import clsx from "clsx";
import dynamic from "next/dynamic";
import PageSectionBlocks from "@components/PageSectionBlocks";
import MaxWidth from "@components/MaxWidth";
import Image from 'next/image'
import getImageBlurOptions from "@lib/getImageBlurOptions";
import styled from "styled-components";
import DotBorder from "./DotBorder";

export const Wrapper = styled(MaxWidth)`
	${(props) => `${props.customCss}`}
`;

const Container = ({
	maxWidth,
	backgroundColor,
	backgroundImage,
	backgroundImageBlur,
	customCss,
	children,
	horizontalAlignment,
	verticalAlignment,
	enableGridSizing,
	gridMode,
	gridGap,
	gridColumns,
	gridPosts,
	reverseOrderOnMobile,
	forms,
}) => {
	return (
		<>
			<Wrapper
				maxWidth={maxWidth}
				style={{ backgroundColor }}
				customCss={customCss}
				className={clsx(
					"Container w-full relative z-10 px-5 xl:px-0 container-test",
					{
						"flex flex-wrap lg:flex-row ": !gridMode,
						"flex-col": !reverseOrderOnMobile,
						"flex-col-reverse": reverseOrderOnMobile,
						"grid-cols-1": gridMode && gridColumns !== 4,
						"lg:grid-cols-1": gridMode && gridColumns === 1,
						"lg:grid-cols-2": gridMode && gridColumns === 2,
						"lg:grid-cols-3": gridMode && gridColumns === 3,
						"grid-cols-2 lg:grid-cols-4": gridMode && gridColumns === 4,
					},
					"mx-auto",
					{
						grid: gridMode,
						"gap-7.5": gridMode && gridGap == "sm",
						"gap-15": gridMode && gridGap == "md",
						"gap-22": gridMode && gridGap == "lg",
						"gap-30": gridMode && gridGap == "xl",
					},
					{
						"justify-start": horizontalAlignment == "start",
						"justify-end": horizontalAlignment == "end",
						"justify-center": horizontalAlignment == "center",
						"justify-between": horizontalAlignment == "space-between",
					},
					{
						"items-start": verticalAlignment == "flex-start",
						"items-center": verticalAlignment == "center",
						"items-end": verticalAlignment == "flex-end",
						"items-stretch": verticalAlignment == "stretch",
					},
					{
						"box-sizing": enableGridSizing,
					}
				)}
			>
				{children.map((child, index) => {
					//console.log(child);
					return (
						<PageSectionBlocks
							gridPosts={gridPosts}
							section={child}
							key={index}
							forms={forms}
							subColumn={child.typeHandle === "column" && gridMode}
						/>
					);
				})}
				{enableGridSizing && <DotBorder />}
			</Wrapper>
			{!!backgroundImage?.url && (
				<div
					className={clsx(
						"container__backgroundImage",
						"h-full w-full max-w-screen-3xl m-auto inset-0 absolute z-0"
					)}
				>
					<Image
						src={backgroundImage.url}
						layout="fill"
						objectFit="cover"
						alt={backgroundImage.title}
						draggable={false}
						{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
					/>
				</div>
			)}
		</>
	);
};

export default Container;
