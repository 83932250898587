import clsx from "clsx";

import VideoEmbed from "@components/VideoEmbed";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";



const VideoModal = ({
  deprecatedMode = false,
  videoUrl = null,
  isVideoOpen = false,
  onClose = () => null,
}): JSX.Element => {

  return (
    <Transition
      show={isVideoOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        onClose={onClose}
        className={clsx(
          "VideoModal",
          "fixed z-50 inset-0 overflow-y-auto flex items-center justify-center"
        )}
      >
        {/* Use the overlay to style a dim backdrop for your dialog */}
        <Dialog.Overlay
          className={clsx(
            "VideoModal__dialog",
            "fixed inset-0 bg-black-01 opacity-50"
          )}
        />

        <div className={clsx("VideoModal__videoEmbed", "relative", "w-full")}>
          <VideoEmbed url={videoUrl} autoplay videoStyle="inModal" onClose={onClose}/>
          
        </div>
      </Dialog>
    </Transition>
  );
};

export default VideoModal;
