import clsx from "clsx";

import BodyText from "@components/BodyText";

const BodyTextColumns = ({ columns, textColor, maxWidth }) => {
	return (
		<div className={clsx("BodyTextColumns")}>
			<div
				className={clsx(
					"BodyTextColumns__columns",
					"flex flex-col lg:flex-row",
					"mx-auto",
					"lg:space-x-7.5",
					"space-y-7.5 lg:space-y-0",
					{
						"justify-center w-full": columns.length === 1,
						"max-w-screen-xl": !maxWidth || maxWidth === "max-w-screen-xl",
						"max-w-prose": maxWidth === "max-w-prose",
						"max-w-xs": maxWidth === "max-w-xs",
						"max-w-sm": maxWidth === "max-w-sm",
						"max-w-md": maxWidth === "max-w-md",
						"max-w-lg": maxWidth === "max-w-lg",
						"max-w-xl": maxWidth === "max-w-xl",
						"max-w-screen-half": maxWidth === "max-w-screen-half",
						"max-w-screen-sm": maxWidth === "max-w-screen-sm",
						"max-w-screen-md": maxWidth === "max-w-screen-md",
						"max-w-screen-lg": maxWidth === "max-w-screen-lg",
						"max-w-screen-2xl": maxWidth === "max-w-screen-2xl",
					}
				)}
			>
				{columns.map((column) => {
					return (
						<div
							key={column.id}
							className={clsx("BodyTextColumns__column", "px-5")}
						>
							<BodyText
								text={column.bodyText}
								textColor={textColor}
								fullWidth={columns.length === 1}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default BodyTextColumns;
