import clsx from "clsx";
import dynamic from "next/dynamic";

const ReviewsColumn = dynamic(() => import("@components/ReviewsColumn"));

interface IProps {
	borderColor: string;
	legacyBackgroundColor: string;
	textColor: string;
	size: "xl" | "lg" | "md" | "sm";
	children: object;
}

const ReviewsRow = ({
	legacyBackgroundColor,
	borderColor,
	borderType,
	textColor,
	children,
}) => {
	return (
		<div className={clsx("BodyTextColumns")}>
			<div
				className={clsx(
					"Reviews__row",
					"flex flex-col lg:flex-row",
					"mx-auto",
					"max-w-screen-xl px-5",
					"space-y-7.5 lg:space-y-0"
				)}
				style={{ borderColor: borderColor }}
			>
				{children.map((column, index) => {
					return (
						<div
							key={column.id}
							className={clsx("Reviews__column flex", {
								"w-full": children.length === 1,
								"w-full lg:w-1/2": children.length === 2,
								"w-full lg:w-1/3": children.length === 3,
								"w-full lg:w-1/4": children.length === 4,
								"md:py-7.5 md:px-7.5 first:pl-0 last:pr-0":
									borderType === "sides",
								"md:border-r":
									(borderType === "sides" && index + 1 !== children.length) ||
									(borderType === "complete" && index + 1 === children.length),
								"border-l border-b": borderType === "complete",
								"border-t": borderType === "complete",
							})}
							style={{
								color: textColor,
								backgroundColor: legacyBackgroundColor,
								borderColor:
									borderColor !== "transparent" ? borderColor : "transparent",
							}}
						>
							<ReviewsColumn borderType={borderType} borderColor={borderColor}>
								{column.children}
							</ReviewsColumn>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ReviewsRow;
