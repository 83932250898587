import clsx from "clsx";

import LogoBlock from "@components/LogoBlock";

const LogoGrid = ({
	logos,
	legacyBackgroundColor,
	verticalPadding = "none",
}) => {
	return (
		<div className={clsx("LogoGrid")} style={{ legacyBackgroundColor }}>
			<div
				className={clsx(
					"LogoGrid__content",
					"max-w-screen-xl mx-auto",
					"px-5",
					"select-none",
					{
						"py-3 lg:py-3": verticalPadding === "sm",
						"py-5 lg:py-7.5": verticalPadding === "md",
						"py-15 lg:py-30": verticalPadding === "lg",
						"py-30 lg:py-60": verticalPadding === "xl",
					}
				)}
			>
				<div
					className={clsx(
						"LogoGrid__logos",
						"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-none lg:grid-flow-col lg:auto-cols-auto gap-x-15 items-center"
					)}
				>
					{logos.map((logo) => {
						const image = logo.image[0];

						return (
							<LogoBlock key={image.id} image={image} withMargin={false} />
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default LogoGrid;
