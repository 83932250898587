import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'

import HeadingTag from "@components/HeadingTag";

const DefenceInDepth = ({
	heading,
	subHeading,
	contentColumns,
	legacyBackgroundColor,
	textColor,
	highlightColor,
}) => {
	return (
		<div
			className={clsx("DefenceInDepth")}
			style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
		>
			<div
				className={clsx(
					"DefenceInDepth__content",
					"relative",
					"max-w-screen-xl mx-auto lg:px-15"
				)}
			>
				<div
					className={clsx(
						"DefenceInDepth__header",
						"p-7.5 lg:py-7.5",
						"border-b border-white-01"
					)}
				>
					<HeadingTag
						tag="h2"
						className={clsx(
							"DefenceInDepth__heading",
							"text-3xl lg:text-4xl 3xl:text-5xl text-center",
							"max-w-2xl",
							"mx-auto"
						)}
					>
						{heading}
					</HeadingTag>
					{!!subHeading && (
						<div
							className={clsx(
								"DefenceInDepth__subHeading",
								"text-center",
								"max-w-lg",
								"mx-auto",
								"mt-7.5"
							)}
						>
							{subHeading}
						</div>
					)}
				</div>

				<div
					className={clsx(
						"DefenceInDeptch__union",
						"w-[120px] lg:w-[163px] h-[120px] lg:h-[163px]",
						"absolute top-1/2 lg:top-30 left-1/2",
						"-translate-x-1/2 translate-y-8 lg:translate-y-72",
						"ml-[-2px]"
					)}
				>
					<svg
						viewBox="0 0 163 163"
						fill={legacyBackgroundColor}
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle
							cx="81.5"
							cy="81.5"
							r="80.5"
							stroke="#fff"
							strokeWidth="2"
						/>
						<path fill={highlightColor} d="M81 45h2v73h-2z" />
						<path fill={highlightColor} d="M118 80v2H45v-2z" />
					</svg>
				</div>

				<div
					className={clsx(
						"DefenceInDepth__columns",
						"flex flex-col lg:flex-row"
					)}
				>
					{contentColumns.map((column, index) => {
						const icon = column.icon[0];
						const image = column.image[0];

						return (
							<div
								key={column.id}
								className={clsx(
									"DefenceInDepth__column",
									"flex flex-col",
									"lg:w-1/2",
									"dashed-border-x lg:dashed-border-y last-of-type:bg-none",
									"pb-15 lg:pb-7.5"
								)}
							>
								<div
									className={clsx("DefenceInDeptch__columnHeader", "py-10", {
										"px-5 lg:pr-30 lg:pl-7.5": index == 0,
										"px-5 lg:pl-30 lg:pr-7.5": index == 1,
									})}
								>
									<div
										className={clsx(
											"DefenceInDeptch__iconWrapper",
											"relative",
											"w-[60px] h-[60px]",
											"mb-7.5"
										)}
									>
										<Image
											src={icon.url}
											layout="fill"
											objectFit="contain"
											objectPosition="center left"
											alt={icon.title}
											className={clsx("DefenceInDepth__icon")}
										/>
									</div>
									<div
										className={clsx(
											"DefenceInDeptch__columnHeading",
											"text-2xl lg:text-3xl"
										)}
									>
										{column.heading}
									</div>
                </div>
                {!!column?.text && (
                  <div
                    className={clsx(
                      "DefenceInDeptch__text",
                      "prose lg:prose-2xl",
                      "mb-7.5 lg:mb-0 px-7.5",
                      {
                        "lg:pr-30 lg:pl-7.5": index == 0,
                        "lg:pl-30 lg:pr-7.5": index == 1,
                      },
                      "lg:mb-7.5"
                    )}
                  >
                    {parse(column.text)}
                  </div>
                )}
								<div
									className={clsx(
										"DefenceInDeptch__imageWrapper",
										"mt-auto",
										"flex justify-center self-end"
									)}
								>
									<Image
										src={image.url}
										width={image.width}
										height={image.height}
										alt={image.title}
										className={clsx("DefenceInDepth__image")}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default DefenceInDepth;
