import { Box, Heading, Text } from "@chakra-ui/react";
import PageSections from "@components/PageSections";
import clsx from "clsx";
import sample from "lodash/sample";
import React from "react";
import styled from "styled-components";

import AwardsGrid from "@components/AwardsGrid";
import BodyText from "@components/BodyText";
import BodyTextColumns from "@components/BodyTextColumns";
import ButtonBlock from "@components/ButtonBlock";
import CallToAction from "@components/CallToAction";
import CareersList from "@components/CareersList";
import Column from "@components/Column";
import Container from "@components/Container";
import ContentBlocks from "@components/ContentBlocks";
import ContentList from "@components/ContentList";
import DefenceInDepth from "@components/DefenceInDepth";
import Embed from "@components/Embed";
import EntryCarouselSection from "@components/EntryCarouselSection";
import EntryGrid from "@components/EntryGrid";
import FormSection from "@components/FormSection";
import Header from "@components/Header";
import HeadingTag from "@components/HeadingTag";
import Hero from "@components/Hero";
import IframeEmbed from "@components/IframeEmbed";
import IllustrationGrid from "@components/IllustrationGrid";
import ImageGrid from "@components/ImageGrid";
import LogoCarousel from "@components/LogoCarousel";
import LogoGrid from "@components/LogoGrid";
import Quotation from "@components/Quotation";
import ReviewsRow from "@components/ReviewsRow";
import SideBySide from "@components/SideBySide";
import Sitemap from "@components/Sitemap";
import SitemapItem from "@components/SitemapItem";
import Spacer from "@components/Spacer";
import StatsGrid from "@components/StatsGrid";
import Testimonials from "@components/Testimonials";
import VideoEmbed from "@components/VideoEmbed";
import PageImage from "@sharedComponents/PageImage";

interface IProps {
	section?: any;
	forms?: any[];
	gridPosts?: any[];
	isFirst?: boolean;
	subColumn?: boolean;
	gridMode?: boolean;
}

const PageSectionBlocks = ({
	section,
	isFirst = false,
	subColumn = false,
	gridPosts = null,
	forms = null,
	gridMode = false,
}: IProps) => {
	let image;
	let imageBlur;
	let mobileImage;
	let mobileImageBlur;
	let backgroundImage;
	let backgroundImageBlur;
	let mobileBackgroundImage;
	let mobileBackgroundImageBlur;
	let icon;
	let iconBlur;
	let heroImage;
	let heroImageBlur;
	let mobileHeroImage;
	let mobileHeroImageBlur;
	let backgroundOverlay;
	let logo;
	let logoBlur;
	let mediaBackgroundImage;
	let mediaBackgroundImageBlur;
	let form;

	switch (section.typeHandle) {
		case "hero":
			heroImage = section.heroImage[0];
			heroImageBlur = section.heroImageBlur[0];
			mobileHeroImage = section.mobileHeroImage[0];
			mobileHeroImageBlur = section.mobileHeroImageBlur[0];

			return (
				<Hero
					eyebrowHeading={section.eyebrowHeading}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					heroImage={heroImage}
					heroImageBlur={heroImageBlur}
					mobileHeroImage={mobileHeroImage}
					mobileHeroImageBlur={mobileHeroImageBlur}
					text={section.text}
					secondaryText={section.secondaryText}
					isFirstPageSection={isFirst}
					height={section.sectionHeight}
				/>
			);

		case "header":
			image = section.image[0];
			imageBlur = section.imageBlur[0];
			mobileImage = section.mobileImage[0];
			mobileImageBlur = section.mobileImageBlur[0];
			backgroundImage = section.backgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<Header
					eyebrowHeading={section.eyebrowHeading}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					subHeadingSize={section.subHeadingSize}
					highlightColor={section.highlightColor}
					subHeading={section.subHeading}
					textAlignment={section.textAlignment}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					verticalPadding={section.verticalPadding}
					backgroundImage={backgroundImage}
					backgroundOverlay={backgroundOverlay}
					backgroundImageBlur={backgroundImageBlur}
					image={image}
					imageBlur={imageBlur}
					mobileImage={mobileImage}
					mobileImageBlur={mobileImageBlur}
					isFirstPageSection={isFirst}
					target={section.target}
					buttonTextColor={section.buttonTextColor}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					secondaryTarget={section.secondaryTarget}
					secondaryButtonBackgroundColor={
						section.secondaryButtonBackgroundColor
					}
					secondaryButtonTextColor={section.secondaryButtonTextColor}
					entries={section.entries}
					iconStrip={section.iconStrip}
					videoUrl={section.videoUrl}
					maxWidth={section.maxWidth}
				/>
			);

		case "sideBySide":
			image = section.image[0];
			imageBlur = section.imageBlur[0];
			icon = section.icon[0];
			iconBlur = section.iconBlur[0];
			mobileImage = section.mobileImage[0];
			mobileImageBlur = section.mobileImageBlur[0];
			backgroundImage = section.backgroundImage[0];
			mobileBackgroundImage = section.mobileBackgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			mobileBackgroundImageBlur = section.mobileBackgroundImageBlur[0];
			mediaBackgroundImage = section.mediaBackgroundImage[0];
			mediaBackgroundImageBlur = section.mediaBackgroundImageBlur[0];

			form = !!section.form ? section.form[0] : null;

			return (
				<SideBySide
					legacyBackgroundColor={section.legacyBackgroundColor}
					backgroundImage={backgroundImage}
					mobileBackgroundImage={mobileBackgroundImage}
					backgroundOverlay={backgroundOverlay}
					backgroundImageBlur={backgroundImageBlur}
					mobileBackgroundImageBlur={mobileBackgroundImageBlur}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					secondaryButtonBackgroundColor={
						section.secondaryButtonBackgroundColor
					}
					secondaryButtonTextColor={section.secondaryButtonTextColor}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					headingColor={section.headingColor}
					image={image}
					imageBlur={imageBlur}
					icon={icon}
					iconBlur={iconBlur}
					indentText={section.indentText}
					centerContent={section.centerContent}
					mediaPosition={section.mediaPosition}
					mobileMediaPosition={section.mobileMediaPosition}
					mediaPadding={section.mediaPadding}
					mediaPaddingTop={section.mediaPaddingTop}
					mediaPaddingBottom={section.mediaPaddingBottom}
					mobileImage={mobileImage || image}
					mobileImageBlur={mobileImageBlur || imageBlur}
					target={section.target}
					secondaryTarget={section.secondaryTarget}
					text={section.text}
					textColor={section.textColor}
					textPaddingTop={section.textPaddingTop}
					textPaddingMediaSide={section.textPaddingMediaSide}
					videoUrl={section.videoUrl}
					videoStyle={section.videoStyle}
					isFirstPageSection={isFirst}
					overrideDefaultFormValues={section.overrideDefaultFormValues}
					formDetails={section.formDetails}
					form={form}
					formHeading={section.formHeading}
					showBackgroundGrid={section.showBackgroundGrid}
					fullWidth={section.fullWidth}
					wideContainer={section.wideContainer}
					leftAlignPlayButton={section.leftAlignPlayButton}
					mediaBackgroundImage={mediaBackgroundImage}
					mediaBackgroundImageBlur={mediaBackgroundImageBlur}
				/>
			);
		case "logoCarousel":
			return (
				<LogoCarousel
					logos={section.logos}
					legacyBackgroundColor={section.legacyBackgroundColor}
					verticalPadding={section.verticalPadding}
				/>
			);
		case "logoGrid":
			return (
				<LogoGrid
					logos={section.logos}
					legacyBackgroundColor={section.legacyBackgroundColor}
					verticalPadding={section.verticalPadding}
				/>
			);
		case "embedCode":
			return (
				<div className={clsx("max-w-screen-xl mx-auto")}>
					<Embed embedCode={section.embedCode} />
				</div>
			);
		case "illustrationGrid":
			return (
				<IllustrationGrid
					items={section.gridItems}
					heading={section.heading}
					subHeading={section.subHeading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
				/>
			);

		case "contentList":
			return (
				<ContentList
					items={section.contentList}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
				/>
			);
		case "statsGrid":
			backgroundImage = section.backgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			backgroundImageBlur = section.backgroundImageBlur[0];

			return (
				<StatsGrid
					stats={section.stats}
					statsPosition={section.statsPosition}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					text={section.text}
					backgroundImage={backgroundImage}
					backgroundOverlay={backgroundOverlay}
					backgroundImageBlur={backgroundImageBlur}
					legacyBackgroundColor={section.legacyBackgroundColor}
					statTextColor={section.statTextColor}
					textColor={section.textColor}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
				/>
			);

		case "awardsGrid":
			return <AwardsGrid awards={section.awardsGrid} />;
		case "testimonials":
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<Testimonials
					testimonials={section.testimonials}
					useLogoNavigation={section.useLogoNavigation}
					legacyBackgroundColor={section.legacyBackgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					textColor={section.textColor}
					attributionColor={section.attributionColor}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					target={section.target}
				/>
			);
		case "entryCarousel":
			return <EntryCarouselSection section={section} />;
		case "entryGrid":
			const gridEntries = !!gridPosts
				? gridPosts.find(({ sectionId }) => sectionId === section.id).entries
				: null;

			return (
				<EntryGrid
					entries={gridEntries}
					filterBySection={section.filterBySection}
					indentMobile
					showAuthor={false}
					thumbnailAspectRatio="square"
					columns={4}
					itemsToDisplay={section.itemsToDisplay}
				/>
			);
		case "spacer":
			return (
				<Spacer
					size={section.size}
					hideOnMobile={section.hideOnMobile}
					horizontalRule={section.horizontalRule}
					borderColor={section.borderColor}
				/>
			);
		case "callToActions":
			const targetCallToAction = sample(section.callToActions);

			if (!targetCallToAction) return null;

			image = targetCallToAction.image[0];
			imageBlur = targetCallToAction.imageBlur[0];
			mobileImage = targetCallToAction.mobileImage[0];
			mobileImageBlur = targetCallToAction.mobileImageBlur[0];
			backgroundImage = targetCallToAction.backgroundImage[0];
			backgroundImageBlur = targetCallToAction.backgroundImageBlur[0];

			return (
				<CallToAction
					legacyBackgroundColor={targetCallToAction.legacyBackgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					legacyButtonBackgroundColor={
						targetCallToAction.legacyButtonBackgroundColor
					}
					buttonTextColor={targetCallToAction.buttonTextColor}
					heading={targetCallToAction.heading}
					headingTag={targetCallToAction.headingTag}
					headingSize={targetCallToAction.headingSize}
					image={image}
					imageBlur={imageBlur}
					indentText={targetCallToAction.indentText}
					mediaPosition={targetCallToAction.mediaPosition}
					mediaPaddingTop={targetCallToAction.mediaPaddingTop}
					mediaPaddingBottom={targetCallToAction.mediaPaddingBottom}
					mobileImage={mobileImage || image}
					mobileImageBlur={mobileImageBlur || imageBlur}
					target={targetCallToAction.target}
					text={targetCallToAction.text}
					textColor={targetCallToAction.textColor}
					videoStyle={targetCallToAction.videoStyle}
					videoUrl={targetCallToAction.videoUrl}
					form={targetCallToAction.form ? targetCallToAction.form[0] : null}
				/>
			);
		case "videoEmbed":
			if (!section.videoUrl) return null;

			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];

			return (
				<VideoEmbed
					url={section.videoUrl}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					videoStyle={section.videoStyle}
				/>
			);

		case "contentBlocks":
			if (!section.contentBlocks) return null;
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<ContentBlocks
					heading={section.heading}
					subHeading={section.subHeading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					blocks={section.contentBlocks}
					layout={section.layout}
					textColor={section.textColor}
					iconColor={section.iconColor}
					columns={parseInt(section.columns)}
					padding={section.padding}
					borderColor={section.borderColor}
					fullWidth={section.fullWidth}
					centerColumns={section.centerColumns}
					addGap={section.addGap}
					verticalPadding={section.verticalPadding}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					legacyBackgroundColor={section.legacyBackgroundColor}
				/>
			);

		case "quotation":
			image = section.image[0];
			imageBlur = section.imageBlur[0];
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			logo = section.logo[0];
			logoBlur = section.logoBlur[0];

			return (
				<Quotation
					image={image}
					imageBlur={imageBlur}
					videoUrl={section.videoUrl}
					quotation={section.quotation}
					attribution={section.attribution}
					role={section.role}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					mediaPosition={section.mediaPosition}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					logo={logo}
					logoBlur={logoBlur}
				/>
			);

		case "iframeEmbed":
			return <IframeEmbed url={section.iframeUrl} />;

		case "defenceInDepth":
			return (
				<DefenceInDepth
					heading={section.heading}
					subHeading={section.subHeading}
					contentColumns={section.contentColumns}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					highlightColor={section.highlightColor}
				/>
			);

		case "imageGrid":
			return (
				<ImageGrid
					items={section.imageGrid}
					legacyBackgroundColor={section.legacyBackgroundColor}
					columns={section.columns}
				/>
			);

		case "image":
			image = section.image[0];
			imageBlur = section.imageBlur[0];
			mobileImage = section.mobileImage[0];
			mobileImageBlur = section.mobileImageBlur[0];

			return (
				<PageImage
					image={image}
					//imageBlur={imageBlur}
					mobileImage={mobileImage}
					//mobileImageBlur={mobileImageBlur}
					//fullWidth={section.fullWidth}
					maxWidth={section.maxWidth}
					maxWidthPixels={section.maxWidthPixels}
					horizontalAlignment={section.horizontalAlignment}
				/>
			);

		case "sitemap":
			return (
				<Sitemap
					//textColor={section.textColor}
					//legacyBackgroundColor={section.legacyBackgroundColor}
					heading={section.heading}
					//level={section.level}
					//id={section.id}
				>
					{section.children}
				</Sitemap>
			);

		case "sitemapItem":
			//console.log(section.children)
			return (
				<SitemapItem
					target={section.target}
					level={section.level}
					id={section.id}
				>
					{section.children}
				</SitemapItem>
			);

		case "bodyText":
			return (
				<>
					{section.bodyText && (
						<BodyText
							text={section.bodyText}
							textColor={section.textColor}
							maxWidth={section.maxWidth}
						/>
					)}
					{!section.bodyText && (
						<BodyTextColumns
							columns={section.bodyTextColumns}
							textColor={section.textColor}
							maxWidth={section.maxWidth}
						/>
					)}
				</>
			);

		case "button":
			return (
				<ButtonBlock
					target={section.target}
					verticalPadding={section.verticalPadding}
					buttonBackgroundColor={section.buttonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					horizontalAlignment={section.horizontalAlignment}
				/>
			);

		case "pageModule":
			const Wrapper = styled.div`
				${section.customCss}
			`;
			return (
				<Wrapper style={{ backgroundColor: section.legacyBackgroundColor }}>
					<PageSections
						sections={section.pageModule[0]?.pageSections}
						isPageModule
					/>
				</Wrapper>
			);

		case "careersList":
			return <CareersList departmentId={section.departmentId} />;

		case "reviewsRow":
			return (
				<ReviewsRow
					borderType={section.borderType}
					borderColor={section.borderColor}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
				>
					{section.children}
				</ReviewsRow>
			);

		case "container":
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<Container
					maxWidth={section.maxWidth}
					backgroundColor={section.backgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					customCss={section.customCss}
					horizontalAlignment={section.horizontalAlignment}
					verticalAlignment={section.verticalAlignment}
					enableGridSizing={section.enableGridSizing}
					gridMode={section.gridMode}
					gridGap={section.gridGap}
					gridColumns={section.gridColumns}
					gridPosts={gridPosts}
					forms={forms}
					reverseOrderOnMobile={section.reverseOrderOnMobile}
				>
					{section.children}
				</Container>
			);
		case "column":
			return (
				<Column
					columnWidth={section.columnWidth}
					constrainToMainContainer={section.constrainToMainContainer}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					backgroundColor={section.backgroundColor}
					verticalAlignment={section.verticalAlignment}
					subColumn={subColumn}
					gridMode={gridMode}
					enableGridSizing={section.enableGridSizing}
					gridPosts={gridPosts}
					forms={forms}
				>
					{section.children}
				</Column>
			);
		case "heading":
			return (
				<HeadingTag
					className={clsx("flex-grow w-full", {
						"text-4xl md:text-6xl": section.headingSize === "xxxl",
						"text-4xl md:text-5xl": section.headingSize === "xxl",
						"text-3xl lg:text-4xl":
							!section.headingSize || section.headingSize === "xl",
						"text-2xl md:text-3xl lg:text-3xl": section.headingSize === "lg",
						"text-2xl": section.headingSize === "md",
						"text-xl": section.headingSize === "sm",
						"text-center": section.textAlignment === "center",
					})}
					style={{ color: section.textColor }}
					tag={section.headingTag}
				>
					{section.heading}
				</HeadingTag>
			);

		case "form":
			form = !!section.form ? section.form[0] : null;

			const formData = !!forms
				? forms.find(({ formId }) => formId === form.formDetails[0].formId)
						.formDetails
				: null;

			return (
				<FormSection
					form={form}
					formData={formData}
					maxWidth={section.maxWidth}
					textColor={section.textColor}
					legacyBackgroundColor={section.legacyBackgroundColor}
					formHeading={section.formHeading}
					formDescription={section.formDescription}
					showInline={section.showInline}
				/>
			);

		default:
			return <div>{section.typeHandle}</div>;
	}
};

export default PageSectionBlocks;
