import debounce from "lodash/debounce";
import { useState } from "react";

import EntryThumbnail from "@components/EntryThumbnail";
import { getEntryCategoryArray } from "@lib/getEntryCategoryArray";

import EntryCarousel from "@components/EntryCarousel";

const EntryCarouselSection = ({ section }) => {
	const [imageHeight, setImageHeight] = useState(100);

	const handleImageHeight = debounce((newImageHeight) => {
		setImageHeight((oldHeight) =>
			newImageHeight > 0 ? newImageHeight : oldHeight
		);
	}, 500);

	const aspectRatio = section.thumbnailAspectRatio || "landscape";

	return (
		<EntryCarousel
			isFlush={section.flushCells}
			thumbnailImageHeight={imageHeight}
			entriesPerSlide={section.entriesPerSlide}
			entryBackgroundColor={section.entryBackgroundColor}
		>
			{section.entries.map((entry, index) => {
				const image = !!entry.image ? entry.image[0] : null;
				const imageBlur = !!entry.imageBlur ? entry.imageBlur[0] : null;

				const targetImage = image;

				return (
					<EntryThumbnail
						key={entry.id}
						image={targetImage}
						imageBlur={imageBlur}
						heading={entry.title}
						summary={entry.summary}
						target={{ element: entry, type: "entry" }}
						categories={getEntryCategoryArray(entry)}
						onImageHeight={handleImageHeight}
						measureImageHeight={index === 0}
						aspectRatio={aspectRatio}
						externalUrl={entry.externalUrl}
					/>
				);
			})}
		</EntryCarousel>
	);
};

export default EntryCarouselSection;
